import axios from 'axios';
import authHeader from "@/service/AuthHeader";
import getEnvironment from "@/service/Environment";

const API_URL = getEnvironment().API_LOGGED_IN_URL + 'child';

export default class ChildService {
    update(id, data) {
        return axios.put(API_URL + `/update/${id}`, data, { headers: authHeader() });
    }

    addConnections(id, data) {
        return axios.post(API_URL + `/add-connections/${id}`, data, { headers: authHeader() });
    }
}
