import axios from 'axios';
import authHeader from "@/service/AuthHeader";
import getEnvironment from "@/service/Environment";

const API_URL = getEnvironment().API_LOGGED_IN_URL + 'parent';

export default class UserService {
    getAll() {
        return axios.get(API_URL + '/list', { headers: authHeader() });
    }

    get(id) {
        return axios.get(API_URL + `/view/${id}`, { headers: authHeader() });
    }

    create(data) {
        const header = Object.assign({'Content-Type': 'application/json'}, authHeader());
        return axios.post(API_URL + '/save', data, { headers:  header });
    }

    forgottenPassword(data) {
        const header = Object.assign({'Content-Type': 'application/json'}, authHeader());
        return axios.post(process.env.VUE_APP_API_URL + 'new-password', data, { headers:  header });
    }

    update(id, data) {
        return axios.put(API_URL + `/update/${id}`, data, { headers: authHeader() });
    }

    delete(id) {
        return axios.delete(API_URL + `/delete/${id}`, { headers: authHeader() });
    }

    extendToken(token) {
        return axios.put(API_URL + `/extend`, {
            token
        }, { headers: authHeader() });
    }
}
