import axios from 'axios';
import authHeader from "@/service/AuthHeader";
import getEnvironment from "@/service/Environment";

const API_URL = getEnvironment().API_LOGGED_IN_URL + 'language';

export default class LanguageService {
    getAll() {
        return axios.get(API_URL + '/list', { headers: authHeader() });
    }

    create(data) {
        const header = Object.assign({'Content-Type': 'application/json'}, authHeader());
        return axios.post(API_URL + '/save', data, { headers:  header });
    }

    update(id, data) {
        return axios.put(API_URL + `/update/${id}`, data, { headers: authHeader() });
    }
}
