<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <Toast/>
                <Toolbar class="mb-4" v-if="!permissionDenied">
                    <template v-slot:start>
                        <div class="my-2">
                            <Button label="Törlés" icon="pi pi-trash" class="p-button-danger"
                                    @click="confirmDeleteSelected" :disabled="!selectedUsers || !selectedUsers.length"/>
                        </div>
                    </template>

                    <template v-slot:end>
                        <Button label="Export" icon="pi pi-download" class="p-button-help" @click="exportToExcel"/>
                    </template>
                </Toolbar>

                <DataTable ref="dt" :value="users" v-model:selection="selectedUsers" dataKey="id" :paginator="true"
                           :rows="10" :filters="filters" :loading="loading"
                           paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                           :rowsPerPageOptions="[5,10,25]"
                           currentPageReportTemplate="" responsiveLayout="scroll" filterDisplay="row"
                           v-model:filters="filters"
                           :globalFilterFields="['userName','nameHU','email','status','childrenNames']">
                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <h5 class="m-0">Felhasználók <br> <strong><small>Összesen: {{ total }}</small></strong></h5>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global'].value" placeholder="Keresés..."/>
                            </span>
                        </div>
                    </template>

                    <Column selectionMode="multiple" headerStyle="width: 1rem"></Column>
                    <Column field="id" header="#" :sortable="true" headerStyle="width:2%; min-width:5rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">#</span>
                            {{ slotProps.data.id }}
                        </template>
                    </Column>
                    <Column field="userName" header="Felhasználónév" :sortable="true"
                            headerStyle="width:15%; min-width:10rem;" :showFilterMenu="false">
                        <template #body="slotProps">
                            <span class="p-column-title">Felhasználónév</span>
                            {{ slotProps.data.userName }}
                        </template>
                        <template #filter="{filterModel,filterCallback}">
                            <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                                       class="p-column-filter" :placeholder="`Keresés...`"/>
                        </template>
                    </Column>
                    <Column field="nameHU" header="Név" :sortable="true" headerStyle="width:15%; min-width:10rem;"
                            :showFilterMenu="false">
                        <template #body="slotProps">
                            <span class="p-column-title">Név</span>
                            {{ slotProps.data.nameHU }}
                        </template>
                        <template #filter="{filterModel,filterCallback}">
                            <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                                       class="p-column-filter" :placeholder="`Keresés...`"/>
                        </template>
                    </Column>
                    <Column field="email" header="E-mail cím" :sortable="true" headerStyle="width:15%; min-width:10rem;"
                            :showFilterMenu="false">
                        <template #body="slotProps">
                            <span class="p-column-title">E-mail cím</span>
                            {{ slotProps.data.email }}
                        </template>
                        <template #filter="{filterModel,filterCallback}">
                            <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                                       class="p-column-filter" :placeholder="`Keresés...`"/>
                        </template>
                    </Column>
                    <Column field="createdAt" header="Regisztráció dátuma" :sortable="true"
                            headerStyle="width:15%; min-width:10rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">Regisztráció dátuma</span>
                            {{ slotProps.data.createdAt }}
                        </template>
                    </Column>
                    <Column field="status" header="Státusz" :sortable="true" headerStyle="width:20%; min-width:10rem;"
                            :showFilterMenu="false">
                        <template #body="slotProps">
                            <span class="p-column-title">Státusz</span>

                            <span class="product-badge status-instock"
                                  v-if="slotProps.data.status === 'active'">Aktív</span>
                            <span class="product-badge status-lowstock" v-else>Inaktív</span>
                        </template>
                        <template #filter="{ filterModel, filterCallback }">
                            <Dropdown v-model="filterModel.value" @change="filterCallback()" :options="statuses"
                                      placeholder="Összes"
                                      class="p-column-filter" :showClear="true" optionLabel="label" optionValue="value">
                                <template #value="slotProps">
                                <span v-if="slotProps.value">
                                    <span v-if="slotProps.value === 'active'">Aktív</span>
                                    <span v-else>Inaktív</span>
                                </span>
                                    <span v-else>{{ slotProps.placeholder }}</span>
                                </template>
                                <template #option="slotProps">
                                    {{ slotProps.option.label }}
                                </template>
                            </Dropdown>
                        </template>
                    </Column>
                    <Column headerStyle="min-width:10rem;">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                                    v-tooltip.top="'Szerkesztés'" @click="edit(slotProps.data)"/>
                            <Button icon="pi pi-eye" class="p-button-rounded p-button-primary mr-2"
                                    v-tooltip.top="'Megtekintés'" @click="show(slotProps.data)"/>
                            <br>
                            <ConfirmPopup></ConfirmPopup>
                            <Button icon="pi pi-sign-in" class="p-button-rounded p-button-secondary mr-2"
                                    v-if="!permissionDenied"
                                    v-tooltip.top="'Belépés a nevében'" @click="login($event, slotProps.data)"/>
                            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger mt-2"
                                    v-if="!permissionDenied"
                                    v-tooltip.top="'Törlés'" @click="confirmDelete(slotProps.data)"/>
                        </template>
                    </Column>
                    <template #empty>
                        Nincs egyetlen felhasználó sem.
                    </template>
                </DataTable>

                <Dialog v-model:visible="userDialog" :style="{width: '800px'}" header="Felhasználó adatai" :modal="true"
                        class="p-fluid">
                    <TabView :style="{minHeight: '500px'}">
                        <TabPanel header="Alapadatok">
                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="lastName">Vezetéknév</label>
                                    <InputText id="lastName" v-model.trim="user.lastName" required="true"
                                               :class="{'p-invalid': submitted && !user.lastName}"/>
                                    <small class="p-invalid" v-if="submitted && !user.lastName">Vezetéknév megadása
                                        kötelező.</small>
                                </div>
                                <div class="field col">
                                    <label for="firstName">Keresztnév</label>
                                    <InputText id="firstName" v-model.trim="user.firstName" required="true"
                                               :class="{'p-invalid': submitted && !user.firstName}"/>
                                    <small class="p-invalid" v-if="submitted && !user.firstName">Keresztnév megadása
                                        kötelező.</small>
                                </div>
                            </div>

                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="name">Házirend</label> <br>
                                    <InputSwitch v-model="user.policyAccepted"/>
                                </div>

                                <div class="field col">
                                    <label for="name">Adatvédelmi szabályzat</label> <br>
                                    <InputSwitch v-model="user.privacyPolicyAccepted"/>
                                </div>
                            </div>

                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="name">Igazolatlan módon mulasztott ő vagy gyermeke foglalkozást</label>
                                    <br>
                                    <InputSwitch v-model="user.unexcusedAbsence"/>
                                </div>
                            </div>

                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="name">Tiltott státusz</label> <br>
                                    <InputSwitch v-model="user.isForbidden"/>
                                </div>
                            </div>

                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="name">Beszélt nyelvek</label>
                                    <MultiSelect id="gameProperty" ::modelValue="languageIds" v-model="languageIds"
                                                 :options="languages" :emptyMessage="'Nincs találat'"
                                                 optionLabel="name" optionValue="id" :filter="true" filterBy="name"
                                                 :showClear="true" placeholder="Kérlek válassz...">
                                        <template #option="slotProps">
                                            <div class="flex align-items-center">
                                                <div>{{ slotProps.option.name }}</div>
                                            </div>
                                        </template>
                                    </MultiSelect>
                                </div>
                            </div>

                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="inventoryStatus">Státusz</label>
                                    <Dropdown id="inventoryStatus" v-model="user.status" :options="statuses"
                                              optionLabel="label" placeholder="Státusz">
                                        <template #value="slotProps">
                                            <span v-if="slotProps.value">
                                                <span class="product-badge status-instock"
                                                      v-if="slotProps.value == 'active' || slotProps.value.value == 'active'">
                                                    Aktív
                                                </span>
                                                <span class="product-badge status-lowstock" v-else>Inaktív</span>
                                            </span>
                                            <span v-else>
                                                {{ slotProps.placeholder }}
                                            </span>
                                        </template>
                                    </Dropdown>
                                </div>
                                <div class="field col">
                                    <label for="name">Aktiválás dátuma</label>
                                    <Calendar inputId="calendar" v-model="actualDate" :disabledDays="[0,6]"
                                              :manualInput="false" dateFormat="yy-mm-dd"></Calendar>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Elérhetőségek">
                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="lastName">Irányítószám</label>
                                    <InputText id="lastName" v-model.trim="user.contactDetail.zipCode" required="true"
                                               :class="{'p-invalid': submitted && !user.contactDetail.zipCode}"/>
                                    <small class="p-invalid" v-if="submitted && !user.contactDetail.zipCode">Irányítószám
                                        megadása kötelező.</small>
                                </div>
                                <div class="field col">
                                    <label for="firstName">Közterület</label>
                                    <InputText id="firstName" v-model.trim="user.contactDetail.street" required="true"
                                               :class="{'p-invalid': submitted && !user.contactDetail.street}"/>
                                    <small class="p-invalid" v-if="submitted && !user.contactDetail.street">Közterület
                                        megadása kötelező.</small>
                                </div>
                            </div>

                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="lastName">Házszám</label>
                                    <InputText id="lastName" v-model.trim="user.contactDetail.houseNumber"
                                               required="true"
                                               :class="{'p-invalid': submitted && !user.contactDetail.houseNumber}"/>
                                    <small class="p-invalid" v-if="submitted && !user.contactDetail.houseNumber">Házszám
                                        megadása kötelező.</small>
                                </div>
                                <div class="field col">
                                    <label for="firstName">Emelet</label>
                                    <InputText id="floor" v-model.trim="user.contactDetail.floor"/>
                                </div>
                            </div>

                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="lastName">Ajtó</label>
                                    <InputText id="door" v-model.trim="user.contactDetail.door"/>
                                </div>
                                <div class="field col">
                                    <label for="firstName">Telefonszám</label>
                                    <InputText id="firstName" v-model.trim="user.contactDetail.phoneNumber"
                                               required="true"
                                               :class="{'p-invalid': submitted && !user.contactDetail.phoneNumber}"/>
                                    <small class="p-invalid" v-if="submitted && !user.contactDetail.phoneNumber">Telefonszám
                                        megadása kötelező.</small>
                                </div>
                            </div>
                        </TabPanel>
                    </TabView>


                    <template #footer>
                        <Button label="Mégse" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
                        <Button label="Mentés" icon="pi pi-check" class="p-button-success" @click="saveUser"
                                :loading="loading"/>
                    </template>
                </Dialog>

                <Dialog v-model:visible="showUserDialog" :style="{width: '1000px'}" header="Felhasználó adatai"
                        :modal="true" class="p-fluid">
                    <div class="surface-card" v-if="!loading">
                        <TabView :style="{minHeight: '600px'}">
                            <TabPanel header="Alapadatok">
                                <ul class="list-none p-0 m-0 border-top-1 border-300">
                                    <li class="flex align-items-center py-3 px-2 flex-wrap surface-50">
                                        <div class="text-500 w-full md:w-5 font-medium">ID</div>
                                        <div class="text-900 w-full md:w-5">{{ user.id }}</div>
                                    </li>
                                    <li class="flex align-items-center py-3 px-2 flex-wrap surface-50">
                                        <div class="text-500 w-full md:w-5 font-medium">Felhasználónév</div>
                                        <div class="text-900 w-full md:w-5">{{ user.userName }}</div>
                                    </li>
                                    <li class="flex align-items-center py-3 px-2 flex-wrap">
                                        <div class="text-500 w-full md:w-5 font-medium">Név</div>
                                        <div class="text-900 w-full md:w-5">{{ user.nameHU }}</div>
                                    </li>
                                    <li class="flex align-items-center py-3 px-2 flex-wrap surface-50">
                                        <div class="text-500 w-full md:w-5 font-medium">E-mail cím</div>
                                        <div class="text-900 w-full md:w-5">{{ user.email }}</div>
                                    </li>
                                    <li class="flex align-items-center py-3 px-2 flex-wrap">
                                        <div class="text-500 w-full md:w-5 font-medium">Státusz</div>
                                        <div class="text-900 w-full md:w-5">{{
                                                user.isActive ? 'Aktív' : 'Inaktív'
                                            }}
                                        </div>
                                    </li>
                                    <li class="flex align-items-center py-3 px-2 flex-wrap surface-50">
                                        <div class="text-500 w-full md:w-5 font-medium">Tiltott státusz</div>
                                        <div class="text-900 w-full md:w-5">{{
                                                user.isForbidden ? 'Igen' : 'Nem'
                                            }}
                                        </div>
                                    </li>
                                    <li class="flex align-items-center py-3 px-2 flex-wrap">
                                        <div class="text-500 w-full md:w-5 font-medium">Házirendet elfogadta</div>
                                        <div class="text-900 w-full md:w-5">{{
                                                user.policyAccepted ? 'Igen' : 'Nem'
                                            }}
                                        </div>
                                    </li>
                                    <li class="flex align-items-center py-3 px-2 flex-wrap surface-50">
                                        <div class="text-500 w-full md:w-5 font-medium">Adatvédelmi szabályzatot
                                            elfogadta
                                        </div>
                                        <div class="text-900 w-full md:w-5">
                                            {{ user.privacyPolicyAccepted ? 'Igen' : 'Nem' }}
                                        </div>
                                    </li>
                                    <li class="flex align-items-center py-3 px-2 flex-wrap">
                                        <div class="text-500 w-full md:w-5 font-medium">Szeretne hírlevelet</div>
                                        <div class="text-900 w-full md:w-5">
                                            {{ user.newsletter ? 'Igen' : 'Nem' }}
                                        </div>
                                    </li>
                                    <li class="flex align-items-center py-3 px-2 flex-wrap surface-50">
                                        <div class="text-500 w-full md:w-5 font-medium">Igazolatlan módon mulasztott
                                        </div>
                                        <div class="text-900 w-full md:w-5">{{
                                                user.unexcusedAbsence ? 'Igen' : 'Nem'
                                            }}
                                        </div>
                                    </li>
                                    <li class="flex align-items-center py-3 px-2 flex-wrap">
                                        <div class="text-500 w-full md:w-5 font-medium">Beszélt nyelvek</div>
                                        <div class="text-900 w-full md:w-5">
                                            <span v-if="user.languages.length > 0">{{
                                                    user.languages.map((lang) => lang.name).join(",")
                                                }}</span>
                                            <span v-else>Nincsenek beállítva</span>
                                        </div>
                                    </li>
                                </ul>
                            </TabPanel>
                            <TabPanel header="Elérhetőségek">
                                <ul class="list-none p-0 m-0 border-top-1 border-300">
                                    <li class="flex align-items-center py-3 px-2 flex-wrap surface-50">
                                        <div class="text-500 w-full md:w-5 font-medium">Irányítószám</div>
                                        <div class="text-900 w-full md:w-5">{{ user.contactDetail.zipCode }}</div>
                                    </li>
                                    <li class="flex align-items-center py-3 px-2 flex-wrap">
                                        <div class="text-500 w-full md:w-5 font-medium">Közterület</div>
                                        <div class="text-900 w-full md:w-5">{{ user.contactDetail.street }}</div>
                                    </li>
                                    <li class="flex align-items-center py-3 px-2 flex-wrap surface-50">
                                        <div class="text-500 w-full md:w-5 font-medium">Házszám</div>
                                        <div class="text-900 w-full md:w-5">{{ user.contactDetail.houseNumber }}</div>
                                    </li>
                                    <li class="flex align-items-center py-3 px-2 flex-wrap">
                                        <div class="text-500 w-full md:w-5 font-medium">Emelet</div>
                                        <div class="text-900 w-full md:w-5">{{ user.contactDetail.floor }}</div>
                                    </li>
                                    <li class="flex align-items-center py-3 px-2 flex-wrap surface-50">
                                        <div class="text-500 w-full md:w-5 font-medium">Ajtó</div>
                                        <div class="text-900 w-full md:w-5">{{ user.contactDetail.door }}</div>
                                    </li>
                                    <li class="flex align-items-center py-3 px-2 flex-wrap">
                                        <div class="text-500 w-full md:w-5 font-medium">Telefonszám</div>
                                        <div class="text-900 w-full md:w-5">{{ user.contactDetail.phoneNumber }}</div>
                                    </li>
                                </ul>
                            </TabPanel>
                            <TabPanel header="Gyermekek">
                                <DataTable :value="user.children" class="mt-3" responsiveLayout="scroll">
                                    <Column field="id" header="#" :style="{width:'100px'}"></Column>
                                    <Column field="nameHU" header="Név"></Column>
                                    <Column field="gender" header="Nem"></Column>
                                    <Column field="birthDate" header="Születésnap"></Column>
                                    <Column field="age" header="Életkor"></Column>
                                    <Column field="acclimatization" header="Beszoktatós">
                                        <template #body="slotProps">
                                            <span class="p-column-title">Beszoktatós</span>
                                            {{ slotProps.data.acclimatization ? 'Igen' : 'Nem' }}
                                        </template>
                                    </Column>
                                    <Column>
                                        <template #body="slotProps">
                                            <Button icon="pi pi-eye" class="p-button-rounded p-button-primary mr-2"
                                                    v-tooltip.top="'Megtekintés'" @click="showChild(slotProps.data)"/>
                                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                                                    v-tooltip.top="'Szerkesztés'" @click="editChild(slotProps.data)"/>
                                            <Button icon="pi pi-users" class="p-button-rounded p-button-secondary mr-2"
                                                    v-tooltip.top="'Kapcsolattartók'" @click="showConnections(slotProps.data)"/>
                                        </template>
                                    </Column>
                                </DataTable>
                            </TabPanel>
                        </TabView>
                    </div>
                </Dialog>

                <Dialog v-model:visible="showChildDialog" :style="{width: '800px'}" header="Gyermek adatai"
                        :modal="true" class="p-fluid">
                    <div class="surface-card" v-if="!loading" :style="{minHeight: '600px'}">
                        <ul class="list-none p-0 m-0 border-top-1 border-300">
                            <li class="flex align-items-center py-3 px-2 flex-wrap surface-50">
                                <div class="text-500 w-full md:w-5 font-medium">Név</div>
                                <div class="text-900 w-full md:w-5">{{ child.name }}</div>
                            </li>
                            <li class="flex align-items-center py-3 px-2 flex-wrap">
                                <div class="text-500 w-full md:w-5 font-medium">Nem</div>
                                <div class="text-900 w-full md:w-5">{{ child.gender }}</div>
                            </li>
                            <li class="flex align-items-center py-3 px-2 flex-wrap surface-50">
                                <div class="text-500 w-full md:w-5 font-medium">Hogyan szólitsuk</div>
                                <div class="text-900 w-full md:w-5">{{ child.howToCall }}</div>
                            </li>
                            <li class="flex align-items-center py-3 px-2 flex-wrap">
                                <div class="text-500 w-full md:w-5 font-medium">Születésnap</div>
                                <div class="text-900 w-full md:w-5">{{ child.birthDate }}</div>
                            </li>
                            <li class="flex align-items-center py-3 px-2 flex-wrap surface-50">
                                <div class="text-500 w-full md:w-5 font-medium">Életkor</div>
                                <div class="text-900 w-full md:w-5">{{ child.age }}</div>
                            </li>
                            <li class="flex align-items-center py-3 px-2 flex-wrap surface-50">
                                <div class="text-500 w-full md:w-5 font-medium">Beszoktatós</div>
                                <div class="text-900 w-full md:w-5">{{
                                        child.acclimatization ? 'Igen' : 'Nem'
                                    }}
                                </div>
                            </li>
                            <!--                            <li class="flex align-items-center py-3 px-2 flex-wrap">-->
                            <!--                                <div class="text-500 w-full md:w-5 font-medium">Telefonszám</div>-->
                            <!--                                <div class="text-900 w-full md:w-5">{{ child.phoneNumber }}</div>-->
                            <!--                            </li>-->
                            <li class="flex align-items-center py-3 px-2 flex-wrap surface-50">
                                <div class="text-500 w-full md:w-5 font-medium">Speciális igények</div>
                                <div class="text-900 w-full md:w-5">{{ child.specialRequirements }}</div>
                            </li>
                            <li class="flex align-items-center py-3 px-2 flex-wrap">
                                <div class="text-500 w-full md:w-5 font-medium">Beszélt nyelvek</div>
                                <div class="text-900 w-full md:w-5">
                                    <span v-if="child.languages.length > 0">{{
                                            child.languages.map((lang) => lang.name).join(", ")
                                        }}</span>
                                    <span v-else>Nincsenek beállítva</span>
                                </div>
                            </li>
                            <li class="flex align-items-center py-3 px-2 flex-wrap surface-50">
                                <div class="text-500 w-full md:w-5 font-medium">Kapcsolatok</div>
                                <div class="text-900 w-full md:w-5">
                                    {{
                                        child.connections.map((connection) => connection.name + ' (' + connection.phoneNumber + ')').join(", ")
                                    }}
                                </div>
                            </li>
                        </ul>
                    </div>
                </Dialog>

                <Dialog v-model:visible="editChildConnectionsDialog" :style="{width: '900px'}"
                        header="Gyermek kapcsolattartóinak szerkesztése" :modal="true" class="p-fluid">
                    <div class="surface-card" :style="{minHeight: '500px'}">
                        <div v-for="(connection, index) in childConnections" v-bind:key="index">
                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="lastName">Vezetéknév</label>
                                    <InputText id="lastName" v-model.trim="connection.lastName" required="true"
                                               :class="{'p-invalid': childConnectionSubmitted && !connection.lastName}"/>
                                    <small class="p-invalid" v-if="childConnectionSubmitted && !connection.lastName">Vezetéknév megadása
                                        kötelező.</small>
                                </div>
                                <div class="field col">
                                    <label for="firstName">Keresztnév</label>
                                    <InputText id="firstName" v-model.trim="connection.firstName" required="true"
                                               :class="{'p-invalid': childConnectionSubmitted && !connection.firstName}"/>
                                    <small class="p-invalid" v-if="childConnectionSubmitted && !connection.firstName">Keresztnév megadása
                                        kötelező.</small>
                                </div>
                            </div>

                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="lastName">Telefonszám</label>
                                    <InputText id="lastName" v-model.trim="connection.phoneNumber" required="true"
                                               :class="{'p-invalid': childConnectionSubmitted && !connection.phoneNumber}"/>
                                    <small class="p-invalid" v-if="childConnectionSubmitted && !connection.phoneNumber">Telefonszám megadása
                                        kötelező.</small>
                                </div>
                                <div class="field col">
                                    <label for="firstName">Kapcsolata a gyermekkel</label>
                                    <InputText id="firstName" v-model.trim="connection.relationshipType" required="true"
                                               :class="{'p-invalid': childConnectionSubmitted && !connection.relationshipType}"/>
                                    <small class="p-invalid" v-if="childConnectionSubmitted && !connection.relationshipType">Kapcsolata a gyermekkel megadása
                                        kötelező.</small>
                                </div>
                            </div>

                            <div class="formgrid grid">
                                <div class="field col">
                                    <Button label="Törlés" icon="pi pi-trash" class="p-button-danger"
                                            @click="deleteConnection(index)" :style="{marginTop: '25px'}"/>
                                </div>
                            </div>
                            <hr>
                        </div>
                        <div class="formgrid grid">
                            <div class="field col">
                                <Button label="Új személy" icon="pi pi-plus" class="p-button-success" @click="addConnection" :style="{marginTop: '25px'}"/>
                            </div>
                        </div>
                    </div>
                    <template #footer>
                        <Button label="Mégse" icon="pi pi-times" class="p-button-text"
                                @click="editChildConnectionsDialog = false"/>
                        <Button label="Mentés" icon="pi pi-check" class="p-button-success" @click="saveChildConnection"
                                :loading="childConnectionsLoading"/>
                    </template>
                </Dialog>

                <Dialog v-model:visible="editChildDialog" :style="{width: '800px'}"
                        header="Gyermek adatainak szerkesztése" :modal="true" class="p-fluid">
                    <div class="surface-card" :style="{minHeight: '400px'}">
                        <div class="formgrid grid">
                            <div class="field col">
                                <label for="lastName">Vezetéknév</label>
                                <InputText id="lastName" v-model.trim="child.lastName" required="true"
                                           :class="{'p-invalid': childSubmitted && !child.lastName}"/>
                                <small class="p-invalid" v-if="childSubmitted && !child.lastName">Vezetéknév megadása
                                    kötelező.</small>
                            </div>
                            <div class="field col">
                                <label for="firstName">Keresztnév</label>
                                <InputText id="firstName" v-model.trim="child.firstName" required="true"
                                           :class="{'p-invalid': childSubmitted && !child.firstName}"/>
                                <small class="p-invalid" v-if="childSubmitted && !child.firstName">Keresztnév megadása
                                    kötelező.</small>
                            </div>
                        </div>

                        <div class="formgrid grid">
                            <div class="field col">
                                <label for="lastName">Hogyan szólítsuk a gyermeket?</label>
                                <InputText id="door" v-model.trim="child.howToCall"/>
                            </div>
                            <div class="field col">
                                <label for="firstName">Telefonszám</label>
                                <InputText id="firstName" v-model.trim="child.phoneNumber"/>
                            </div>
                        </div>

                        <div class="formgrid grid">
                            <div class="field col">
                                <label for="lastName">Születési dátum</label>
                                <Calendar inputId="calendar" v-model="child.birthDate"
                                          :manualInput="false" dateFormat="yy-mm-dd"
                                          :class="{'p-invalid': childSubmitted && !child.birthDate}"></Calendar>
                            </div>
                            <div class="field col">
                                <label for="firstName">Nem</label>
                                <Dropdown id="inventoryStatus" v-model="child.genderSelect" :options="genders"
                                          optionLabel="label"
                                          :class="{'p-invalid': childSubmitted && !child.genderSelect}"
                                          placeholder="Nem">
                                    <template #value="slotProps">
                                            <span v-if="slotProps.value">
                                                {{ slotProps.value.label }}
                                            </span>
                                        <span v-else>
                                                {{ slotProps.placeholder }}
                                            </span>
                                    </template>
                                </Dropdown>
                            </div>
                        </div>

                        <div class="formgrid grid">
                            <div class="field col">
                                <label for="acclimatization">Beszoktatós</label> <br>
                                <InputSwitch v-model="child.acclimatization"/>
                            </div>
                        </div>

                        <div class="formgrid grid">
                            <div class="field col">
                                <label for="lastName">Speciális igények</label>
                                <Textarea v-model="child.specialRequirements" rows="3" cols="20"/>
                            </div>
                        </div>
                    </div>
                    <template #footer>
                        <Button label="Mégse" icon="pi pi-times" class="p-button-text"
                                @click="editChildDialog = false"/>
                        <Button label="Mentés" icon="pi pi-check" class="p-button-success" @click="saveChild"
                                :loading="childLoading"/>
                    </template>
                </Dialog>

                <Dialog v-model:visible="deleteUserDialog" :style="{width: '550px'}" header="Törlés megerősítése"
                        :modal="true">
                    <div class="flex align-items-center justify-content-center mt-3">
                        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
                        <span
                            v-if="user">Biztosan törölni szeretné a következő felhasználót: <br> <b>{{ user.name }}</b>?</span>
                    </div>
                    <template #footer>
                        <Button label="Nem" icon="pi pi-times" class="p-button-text" @click="deleteUserDialog = false"/>
                        <Button label="Igen" icon="pi pi-check" class="p-button-danger" @click="deleteUser" :loading="deleteLoading"/>
                    </template>
                </Dialog>

                <Dialog v-model:visible="deleteUsersDialog" :style="{width: '550px'}" header="Törlés megerősítése"
                        :modal="true">
                    <div class="flex align-items-center justify-content-center">
                        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
                        <span v-if="user">Biztosan törölni szeretné a kiválasztott felhasználókat?</span>
                    </div>
                    <template #footer>
                        <Button label="Nem" icon="pi pi-times" class="p-button-text"
                                @click="deleteUsersDialog = false"/>
                        <Button label="Igen" icon="pi pi-check" class="p-button-danger" @click="deleteSelectedUsers"/>
                    </template>
                </Dialog>
            </div>
        </div>
    </div>

</template>

<script>
import { FilterMatchMode } from 'primevue/api';
import UserService from "@/service/UserService";
import getEnvironment from "@/service/Environment";
import LanguageService from "@/service/LanguageService";
import ChildService from "@/service/ChildService";
import * as dayjs from "dayjs";
import ExcelJS from 'exceljs';

export default {
    data() {
        return {
            total: 0,
            users: null,
            showUserDialog: false,
            showChildDialog: false,
            editChildDialog: false,
            editChildConnectionsDialog: false,
            userDialog: false,
            deleteUserDialog: false,
            deleteUsersDialog: false,
            user: {},
            child: {},
            childConnections: [],
            connectionSubmitted: false,
            selectedUsers: null,
            filters: {},
            submitted: false,
            childSubmitted: false,
            childConnectionSubmitted: false,
            childConnectionsLoading: false,
            loading: true,
            deleteLoading: false,
            childLoading: false,
            childConnectionLoading: false,
            permissionDenied: true,
            actualDate: null,
            statuses: [
                {
                    label: 'Aktív', value: 'active'
                },
                {
                    label: 'Inaktív', value: 'inactive'
                }
            ],
            forbiddenStatuses: [
                {
                    label: 'Aktív', value: 'active'
                },
                {
                    label: 'Tiltott', value: 'inactive'
                }
            ],
            genders: [
                {
                    label: 'Fiú', value: '0'
                },
                {
                    label: 'Lány', value: '1'
                },
            ],
            languages: [],
            languageIds: []
        }
    },
    userService: null,
    languageService: null,
    childService: null,
    created() {
        this.userService = new UserService();
        this.languageService = new LanguageService();
        this.childService = new ChildService();
        this.initFilters();
    },
    mounted() {
        let user = JSON.parse(localStorage.getItem('userData'));
        this.permissionDenied = user.role === 1;

        this.getAll();
        this.getAllLanguage();
    },
    methods: {
        formatDate(date) {
            let d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
        },
        getAll() {
            this.userService.getAll().then(response => {
                this.total = response.data.total
                this.users = response.data.data
                this.loading = false;
            });
        },
        getAllLanguage() {
            this.languageService.getAll().then(response => {
                response.data.data.map((language) => {
                    this.languages.push({
                        name: language.name, id: language.id
                    })
                });
            });
        },
        hideDialog() {
            this.userDialog = false;
            this.submitted = false;
        },
        saveChild() {
            this.childSubmitted = true;
            this.childLoading = true;

            this.childService.update(this.child.id, {
                firstName: this.child.firstName,
                lastName: this.child.lastName,
                howToCall: this.child.howToCall,
                phoneNumber: this.child.phoneNumber,
                specialRequirements: this.child.specialRequirements,
                gender: parseInt(this.child.genderSelect.value),
                acclimatization: this.child.acclimatization ? this.child.acclimatization : false,
                birthDate: this.child.birthDate instanceof Date ? this.formatDate(this.child.birthDate) : this.child.birthDate,
            }).then(() => {
                this.editChildDialog = false;
                this.showUserDialog = false;
                this.child = {};
                this.getAll()

                this.$toast.add({
                    severity: 'success',
                    summary: 'Sikeres művelet!',
                    detail: 'A gyermek adatai sikeresen módosultak.',
                    life: 5000
                });

                this.childLoading = false;
            }).catch(e => {
                this.$toast.add({
                    severity: "error",
                    summary: "Sikertelen művelet!",
                    detail: e.response.data.error,
                    life: 6000,
                });

                this.childLoading = false;
            });
        },
        saveChildConnection() {
            this.childConnectionSubmitted = true;
            this.childConnectionsLoading = true;

            this.childService.addConnections(this.child.id, {
                connections: this.childConnections
            }).then(() => {
                this.editChildConnectionsDialog = false;
                this.showUserDialog = false;
                this.child = {};
                this.getAll()

                this.$toast.add({
                    severity: 'success',
                    summary: 'Sikeres művelet!',
                    detail: 'A gyermek kapcsolattartóinak adatai sikeresen módosultak.',
                    life: 5000
                });

                this.childConnectionsLoading = false;
            }).catch(e => {
                this.$toast.add({
                    severity: "error",
                    summary: "Sikertelen művelet!",
                    detail: e.response.data.error,
                    life: 6000,
                });

                this.childConnectionsLoading = false;
            });
        },
        saveUser() {
            this.submitted = true;
            this.loading = true;

            if (this.user.name.trim()) {
                let user = {
                    firstName: this.user.firstName,
                    lastName: this.user.lastName,
                    policyAccepted: this.user.policyAccepted ? this.user.policyAccepted : false,
                    privacyPolicyAccepted: this.user.privacyPolicyAccepted ? this.user.privacyPolicyAccepted : false,
                    newsletter: this.user.newsletter ? this.user.newsletter : false,
                    unexcusedAbsence: this.user.unexcusedAbsence ? 1 : 0,
                    isForbidden: this.user.isForbidden ? this.user.isForbidden : false,
                    isActive: this.user.status === 'active' || this.user.status.value === 'active',
                    languages: this.languageIds.map((lang) => lang),
                    zipCode: this.user.contactDetail.zipCode,
                    street: this.user.contactDetail.street,
                    houseNumber: this.user.contactDetail.houseNumber,
                    floor: this.user.contactDetail.floor,
                    door: this.user.contactDetail.door,
                    phoneNumber: this.user.contactDetail.phoneNumber
                };

                let d = new Date(this.actualDate),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();

                if (month.length < 2)
                    month = '0' + month;
                if (day.length < 2)
                    day = '0' + day;

                user.activatedAt = [year, month, day].join('-');

                this.userService.update(this.user.id, user).then(() => {
                    this.userDialog = false;
                    this.user = {};
                    this.getAll()

                    this.$toast.add({
                        severity: 'success',
                        summary: 'Sikeres művelet!',
                        detail: 'A felhasználó adatai sikeresen módosultak.',
                        life: 5000
                    });

                    this.loading = false;
                }).catch(e => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Sikertelen művelet!",
                        detail: e.response.data.error,
                        life: 6000,
                    });

                    this.loading = false;
                });
            }
        },
        edit(user) {
            let d = new Date(),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            this.user = {...user};
            this.actualDate = this.user.activatedAt ? this.user.activatedAt : [year, month, day].join('-');
            this.languageIds = this.user.languages.map(lang => lang.id);
            this.userDialog = true;
        },
        show(user) {
            this.user = {...user};
            this.showUserDialog = true;
        },
        showChild(child) {
            this.child = {...child};
            this.showChildDialog = true;
        },
        editChild(child) {
            this.child = {...child};
            this.editChildDialog = true;
        },
        showConnections(child) {
            this.child = {...child};

            if (child.connections.length > 0) {
                this.childConnections = child.connections;
            }

            this.editChildConnectionsDialog = true;
        },
        addConnection() {
            this.childConnections.push({
                firstName: '',
                lastName: '',
                phoneNumber: '',
                relationshipType: ''
            });
        },
        deleteConnection(index) {
            this.childConnections.splice(index, 1);
        },
        login(event, { token }) {
            this.$confirm.require({
                target: event.currentTarget,
                message: 'Biztosan szeretne belépni a felhasználó nevében?',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Igen',
                rejectLabel: 'Nem',
                accept: () => {
                    this.userService.extendToken(token).then(() => {
                        window.open(getEnvironment().FRONTEND_URL + "admin-login?token=" + token, "_blank");
                    });
                }
            });
        },
        confirmDelete(user) {
            this.user = user;
            this.deleteUserDialog = true;
        },
        deleteUser() {
            this.loading = true;
            this.deleteLoading = true;

            this.userService.delete(this.user.id).then(() => {
                this.deleteUserDialog = false;
                this.user = {};
                this.getAll()

                this.$toast.add({
                    severity: 'success',
                    summary: 'Sikeres művelet!',
                    detail: 'A felhasználó törlése sikeres volt.',
                    life: 5000
                });

                this.loading = false;
                this.deleteLoading = false;
            }).catch(e => {
                this.$toast.add({
                    severity: "error",
                    summary: "Sikertelen művelet!",
                    detail: e.response.data.error,
                    life: 6000,
                });

                this.loading = false;
                this.deleteLoading = false;
            });
        },
        findIndexById(id) {
            let index = -1;
            for (let i = 0; i < this.users.length; i++) {
                if (this.users[i].id === id) {
                    index = i;
                    break;
                }
            }
            return index;
        },
        async exportToExcel() {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Sheet 1');
            worksheet.columns = [
                { header: '#', key: 'id', width: 10 },
                { header: 'Felhasználónév', key: 'userName', width: 40 },
                { header: 'Név', key: 'nameHU', width: 40 },
                { header: 'E-mail cím', key: 'email', width: 40 },
                { header: 'Regisztráció dátuma', key: 'createdAt', width: 20 },
                { header: 'Státusz', key: 'status', width: 10 }
            ];

            const selectedProperties = worksheet.columns.map(column => column.key);
            const data = this.users === null ? [] : this.users;
            const modifiedData = data.map(obj =>
                selectedProperties.reduce((acc, prop) => {
                acc[prop] = obj[prop];
                return acc;
                }, {})
            );

            modifiedData.forEach(row => {
                worksheet.addRow(Object.values(row));
            });

            const statusColumnIndex = selectedProperties.indexOf('status');
            if (statusColumnIndex !== -1) {
                worksheet.getColumn(statusColumnIndex + 1).eachCell((cell, rowNumber) => {
                if (rowNumber > 1) {
                    cell.value = cell.value === 'active' ? 'Aktív' : 'Inaktív';
                }
                });
            }

            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            const today = dayjs().format('YYYY-MM-DD');
            const filename = `${today}_felhasznalok.xlsx`;
            a.download = filename;
            a.click();
        },
        confirmDeleteSelected() {
            this.deleteUsersDialog = true;
        },
        deleteSelectedUsers() {
            this.users = this.users.filter(val => !this.selectedUsers.includes(val));
            this.deleteUsersDialog = false;
            this.selectedUsers = null;
            this.$toast.add({severity: 'success', summary: 'Successful', detail: 'Products Deleted', life: 3000});
        },
        initFilters() {
            this.filters = {
                'global': {
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS
                },
                'status': {
                    value: null,
                    matchMode: FilterMatchMode.EQUALS
                },
                'email': {
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS
                },
                'nameHU': {
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS
                },
                'userName': {
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import '../../assets/demo/badges.scss';
</style>
